import React, { useReducer, createContext } from 'react';

// Create Context Object
export const AppContext = createContext();

const initialState = {
    category: {
        searchTerm: '',
    },
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'SEARCH_CATERGORY_TERM':
            return {
                ...state,
                category: {
                    searchTerm: action.payload,
                },
            };
        default:
            throw new Error();
    }
};

// Create a provider for components to consume and subscribe to changes
export const AppContextProvider = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <AppContext.Provider value={[state, dispatch]}>
            {props.children}
        </AppContext.Provider>
    );
};
